.detail-panel {
  width: 8.333333rem;
}

.detail-panel .detail-panel-head {
  display: flex;
  font-size: 0.5rem;
  border-bottom: 0.027778rem solid;
  padding: 0.138889rem 0;
  justify-content: space-between;
  align-items: flex-end;
}

.detail-panel .detail-panel-head .jump-button {
  font-size: 0.388889rem;
  cursor: pointer;
}

.detail-panel .detail-panel-head .jump-button:hover {
  border-bottom: 0.027778rem solid;
}

.detail-panel .detail-panel-body {
  padding: 0.277778rem 0.555556rem;
}

.detail-panel .detail-panel-body li {
  list-style-type: disc;
  padding: 0.083333rem;
}